import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import Router from 'vue-router';
import RealEstateAlpha from '../components/RealEstateAlpha.vue';
import HowTo from '../components/HowTo.vue';
import MethodSummary from '../components/MethodSummary.vue';
// import MontrealMap from '../components/MontrealMapRent.vue';
import MontrealMap from '../components/MontrealMap.vue';
import ShowAlpha from '../components/ShowAlpha.vue';
import VueSimpleAlert from "vue-simple-alert";
import LogIn from '../components/LogIn.vue';

Vue.use(Router);
Vue.use(VueSimpleAlert);
Vue.use(Vuex);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://alpha.realestatealpha.ai';
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.baseURL = 'https://deploy-rea.onrender.com';
// axios.defaults.baseURL = 'http://127.0.0.1:5000';

const debug = false;
const api_host = debug ? 'http://127.0.0.1:5000/' : 'https://alpha.realestatealpha.ai';
// const api_host = debug ? 'http://127.0.0.1:5000/' : 'https://deploy-rea.onrender.com';

// Vuex store
const store = new Vuex.Store({
  state: {
    isAuthenticated: false,
    user: null
  },
  mutations: {
    setAuth(state, auth) {
      state.isAuthenticated = auth;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('/api/login', credentials);
        console.log('Login response:', response.data);
        if (response.data.user) {
          commit('setUser', response.data.user);
          commit('setAuth', true);
          console.log("Login response " + response.data);
          console.log('Login response headers:', response.headers);
          // await dispatch('checkSession');
          return true;
        }
        return false;
      } catch (error) {
        console.error('Login error:', error);
        return false;
      }
    },
    async logout({ commit }) {
      try {
        await axios.post(api_host + 'api/logout', {}, { withCredentials: true });
        commit('setAuth', false);
        commit('setUser', null);
      } catch (error) {
        console.error('Logout failed:', error);
      }
    },
    async checkAuth({ commit }) {
      try {
        // const response = await axios.get(api_host + 'api/check_session');
        const response = await axios.get(api_host + 'api/check_session', { withCredentials: true });
        console.log('Check session response:', response.data);
        commit('setAuth', response.data.logged_in);
        if (response.data.logged_in) {
          commit('setUser', response.data.user);
        }
      } catch (error) {
        console.error('Check session error:', error);
        commit('setAuth', false);
        commit('setUser', null);
      }
    },
    async checkSession({ commit }) {
      try {
        const response = await axios.get('/api/check_session');
        console.log('Check session response:', response.data);
        commit('setAuth', response.data.logged_in);
        commit('setUser', response.data.user);
        return response.data.logged_in;
      } catch (error) {
        console.error('Check session error:', error);
        commit('setAuth', false);
        commit('setUser', null);
        return false;
      }
    }
  }
});

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'LogIn',
      component: LogIn
    },
    {
      path: '/',
      name: 'RealEstateAlpha',
      component: RealEstateAlpha,
      meta: { requiresAuth: true }
    },
    {
      path: '/howto',
      name: 'HowTo',
      component: HowTo,
      meta: { requiresAuth: true }
    },
    {
      path: '/summary',
      name: 'MethodSummary',
      component: MethodSummary,
      meta: { requiresAuth: true }
    },
    {
      path: '/montrealmap',
      name: 'MontrealMap',
      component: MontrealMap,
      meta: { requiresAuth: true }
    },
    {
      path: '/showAlpha',
      name: 'ShowAlpha',
      component: ShowAlpha,
      props: true,
      meta: { requiresAuth: true }
    }
  ]
});

router.beforeEach((to, from, next) => {
  next();
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (!store.state.isAuthenticated || true) {
  //     next('/login');
  //   } else {
  //     next();
  //   }
  // } else {
  //   next();
  // }
});

export { router, store };
